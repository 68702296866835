import store from "@/stores/";

export default [
  {
    path: "products",
    component: () => import("@/modules/eow/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("eow/productsStore/loadProducts", {
          appId: to.params.id,
        }),
        store.dispatch("eow/productsStore/loadCategories", {
          appId: to.params.id,
        }),
        store.dispatch("eow/productsStore/loadBrands", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-eow-products",
        component: () => import("@/modules/eow/views/products/Dashboard"),
      },
      {
        path: "categories",
        component: () => import("@/modules/eow/views/Template"),
        children: [
          {
            path: "",
            name: "module-eow-products-categories",
            component: () =>
              import("@/modules/eow/views/products/categories/Dashboard"),
          },
          {
            path: ":categoryId",
            name: "module-eow-products-categories-individual",
            component: () =>
              import("@/modules/eow/views/products/categories/Individual"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("eow/productsStore/loadCategory", {
                  appId: to.params.id,
                  categoryId: to.params.categoryId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
        ],
      },
      {
        path: "brands",
        name: "module-eow-product-brands",
        component: () =>
          import("@/modules/eow/views/products/brands/Dashboard"),
      },
      {
        path: "sizes",
        name: "module-eow-product-sizes",
        component: () => import("@/modules/eow/views/products/sizes/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("eow/productsStore/loadSizes", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },

      {
        path: "sizes-guide",
        component: () => import("@/modules/eow/views/Template"),
        children: [
          {
            path: "",
            name: "module-eow-product-sizesguide",
            component: () =>
              import("@/modules/eow/views/products/sizes-guide/Dashboard"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("eow/productsStore/loadGroupConversions", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: ":guideId",
            name: "module-eow-product-sizesguide-individual",
            component: () =>
              import("@/modules/eow/views/products/sizes-guide/Individual"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("eow/productsStore/loadConversions", {
                  appId: to.params.id,
                  guideId: to.params.guideId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
        ],
      },
      {
        path: ":productId",
        name: "module-eow-products-individual",
        component: () => import("@/modules/eow/views/products/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("eow/productsStore/loadProduct", {
              appId: to.params.id,
              productId: to.params.productId,
            }),
            store.dispatch("eow/productsStore/loadSizes", {
              appId: to.params.id,
            }),
            store.dispatch("eow/productsStore/loadGroupConversions", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },

      {
        path: "archived",
        name: "module-eow-product-archived",
        component: () => import("@/modules/eow/views/products/Archived"),
      },
    ],
  },
];
